import React from 'react';
import PropTypes from 'prop-types';
import FormModal from '../../../fetch/FormModal';
import CustomerForm from '../../customerDetails/form/CustomerForm';
import generalFailedPC from '../../../fetch/generalFailedPC';
import locationHOC from '../../../locationProvider/locationHOC';
import {navigateToParametrized} from '../../../../lib/url';
import {Location} from '../../../../constants/propTypesDefinitions';
import {QUERY_PARAMS, ROUTES} from '../../../../constants/navigation';
import {Button} from 'antd';
import {Trans, t} from '@lingui/macro';
import { GLOBAL_DATA } from '../../../../constants/globalData';
import withDataHOC from '../../../dataProvider/withDataHOC';

/**
 * @fero
 */

class AddCustomer extends React.PureComponent {
    static propTypes = {
        location: Location.isRequired,
        [GLOBAL_DATA.RELOAD_DATA]: PropTypes.func.isRequired,
    }.isRequired;

    render() {
        const {location, [GLOBAL_DATA.RELOAD_DATA]: reload} = this.props;
        return <FormModal
            className="full-size-width"
            openNode={
                <Button className="full-size-width" type="primary" icon="plus">
                    <Trans>Pridanie organizácie</Trans>
                </Button>
            }
            values={{isEdit: false}}
            onFinishSuccessful={(id) => {
                reload([GLOBAL_DATA.CUSTOMER_LIST, GLOBAL_DATA.CUSTOMERS]);
                navigateToParametrized(location, ROUTES.CUSTOMER_DETAILS, {[QUERY_PARAMS.ID_PAGE_CUSTOMER]: id});
            }}
            title={<Trans>Pridanie organizácie</Trans>}
            modalClassName="middle-modal"
            Form={CustomerForm}
            Response={null}
            Failed={generalFailedPC(t`Nepodarilo sa pridať organizáciu`)}
        />;
    }

}

export default locationHOC(withDataHOC([GLOBAL_DATA.RELOAD_DATA])(AddCustomer));