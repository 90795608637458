import React from 'react';
import PropTypes from 'prop-types';
import List from '../../../general/List';
import VisibilityIcon from '../../../general/VisibilityIcon';
import NavigateButton from '../../../general/NavigateButton';
import locationHOC from '../../../locationProvider/locationHOC';
import {Icon} from 'antd';
import {navigateToParametrized, getQueryParam} from '../../../../lib/url';
import {ROUTES, QUERY_PARAMS} from '../../../../constants/navigation';
import {Customer} from '../../../../constants/propTypesDefinitions';
import {Trans, t} from '@lingui/macro';

/**
 * @fero
 */

class CustomersList extends React.PureComponent {
    static propTypes = {
        customers: PropTypes.arrayOf(Customer.isRequired).isRequired,
    };

    constructor(props) {
        super(props);

    }

    onClickItem = (customerId) => {
        const {location} = this.props;
        const prevCustomerId = getQueryParam(location, QUERY_PARAMS.ID_PAGE_CUSTOMER);
        if(prevCustomerId == null || prevCustomerId != customerId)
            navigateToParametrized(location, null, {[QUERY_PARAMS.ID_PAGE_CUSTOMER]: customerId});
        else
            navigateToParametrized(location, null, {[QUERY_PARAMS.ID_PAGE_CUSTOMER]: undefined});
    };

    render() {
        const {location, customers} = this.props;
        const idCustomer = getQueryParam(location, QUERY_PARAMS.ID_PAGE_CUSTOMER);
        return <List
            selectedValueId={idCustomer}
            values={customers}
            onItemClick={(customer) => {this.onClickItem(customer.id)}}
            location={location}
            useScrollIntoView={true}
        >
            {customers.map((customer) => {
                return <div
                    key={customer.id} className="d-flex align-items-center mr-0"
                >
                    <VisibilityIcon isVisible={customer.is_visible} tooltip={<Trans>Neaktívny</Trans>}/>
                    <span className="px-2">{customer.name}</span>
                    {idCustomer == customer.id ?
                        <NavigateButton
                            location={location}
                            to={ROUTES.CUSTOMER_DETAILS}
                            queryParams={{
                                [QUERY_PARAMS.ID_PAGE_CUSTOMER]: getQueryParam(location, QUERY_PARAMS.ID_PAGE_CUSTOMER)
                            }}
                            size="small"
                            tooltipTitle={<Trans>Detail</Trans>}
                        >
                            <Icon type="unordered-list" />
                        </NavigateButton> :
                        null
                    }
                </div>
            })}
        </List>;
    }
}

export default locationHOC(CustomersList);